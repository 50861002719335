<template>
  <div class="card">
    <div class="card-header" :id="'heading_' + itemIndex">
      <h2 class="mb-0 text-left">
        <div class="row">
          <a
            class="btn btn-link col text-left"
            type="button"
            data-toggle="collapse"
            :data-target="'#collapse_' + itemIndex"
            aria-expanded="true"
            :aria-controls="'collapse_' + itemIndex"
          >
            {{ title }}
          </a>

          <span :id="'icon_' + itemIndex" class="col-auto">
            <font-awesome-icon
              class="fa-xs"
              aria-setsize="1"
              :icon="['fas', 'plus']"
              v-if="iconIndex == 0"
            />
            <font-awesome-icon class="fa-xs" :icon="['fas', 'minus']" v-else />
          </span>
        </div>
      </h2>
    </div>

    <div
      :id="'collapse_' + itemIndex"
      class="collapse"
      :aria-labelledby="'heading_' + itemIndex"
    >
      <div class="card-body faq-item" v-html="content"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import $ from "jquery";

export default defineComponent({
  name: "FaqItem",
  props: {
    itemIndex: Number,
    title: String,
    content: String,
    dataParentName: String,
  },
  data() {
    return {
      iconIndex: 0,
    };
  },
  created() {
    $(() => {
      $("#collapse_" + this.itemIndex).on("shown.bs.collapse", () => {
        this.iconIndex = 1;
      });

      $("#collapse_" + this.itemIndex).on("hidden.bs.collapse", () => {
        this.iconIndex = 0;
      });

      $("#icon_" + this.itemIndex).click(() => {
        if (this.iconIndex == 0) {
          ($(`#collapse_${this.itemIndex}`) as any).collapse("show");
        } else {
          ($(`#collapse_${this.itemIndex}`) as any).collapse("hide");
        }
      });
    });
  },
});
</script>
<style scoped>
a {
  color: black;
}
</style>
