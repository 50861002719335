<template>
  <div class="changelog-wrapper">
    <div class="changelog-filters">
      <div class="col-8">
        <div class="row">
          <div>
            <input
              id="changelog-filter-feature"
              class="changelog-checkbox"
              type="checkbox"
              :checked="displayFeatures"
              v-model="displayFeatures"
            /><label
              class="changelog-checkbox-label"
              for="changelog-filter-feature"
              >Dodane</label
            >
          </div>

          <div>
            <input
              id="changelog-filter-improvement"
              class="changelog-checkbox"
              type="checkbox"
              :checked="displayImprovments"
              v-model="displayImprovments"
            />
            <label
              class="changelog-checkbox-label"
              for="changelog-filter-improvement"
              >Ulepszenia</label
            >
          </div>

          <div>
            <input
              id="changelog-filter-bug-fix"
              class="changelog-checkbox"
              type="checkbox"
              :checked="displayBugFixes"
              v-model="displayBugFixes"
            /><label
              class="changelog-checkbox-label"
              for="changelog-filter-bug-fix"
              >Poprawki</label
            >
          </div>
        </div>
      </div>

      <div
        class="changelog-scroll-to col-4"
        @mouseover="changelogGoToVersionHovered = true"
        @mouseleave="changelogGoToVersionHovered = false"
      >
        <span
          class="unselectable"
          style="position: absolute; top: 0px; right: 20px"
          >Idź do&nbsp;&nbsp;<font-awesome-icon :icon="['fas', 'angle-down']"
        /></span>

        <ul class="changelog-scroll-to-list" v-if="changelogGoToVersionHovered">
          <li
            class="changelog-scroll-to-list-item"
            v-for="item in items"
            :key="item"
          >
            <a href="#" @click="scrollToDiv($event, 'v_' + item.Version)"
              >v{{ item.Version }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div id="changelog-items-container" class="changelog-items">
      <ChangelogItem
        v-for="(item, index) in items"
        :key="item"
        :itemIndex="index"
        :version="item.Version"
        :date="item.Date"
        :changes="item.Changes"
        :displayFeatures="displayFeatures"
        :displayImprovments="displayImprovments"
        :displayBugFixes="displayBugFixes"
      />

      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="displayImagesLightBox"
        :imgs="images"
        :index="imageIndex"
        @hide="hideImagesLightBox"
      ></vue-easy-lightbox>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ChangelogItem from "./ChangelogItem.vue";
import { ChangelogItemDataModel } from "./../models/changelogItemDataModel";
//import $ from "jquery";

export default defineComponent({
  name: "ChangelogViewer",
  props: {
    items: Array as () => Array<ChangelogItemDataModel>,
  },
  data() {
    return {
      checkedFilters: [],
      changelogGoToVersionHovered: false,
      displayFeatures: true,
      displayImprovments: true,
      displayBugFixes: true,
      displayImagesLightBox: false,
      images: [] as string[],
      imageIndex: 0,
    };
  },
  mounted() {
    const rootChangelogElement = document.getElementById(
      "changelog-items-container"
    );

    if (rootChangelogElement != null) {
      const imagesElements = rootChangelogElement.getElementsByTagName("img");

      for (let i = 0; i < imagesElements.length; i++) {
        this.images.push(imagesElements[i].src);
        imagesElements[i].onclick = () => {
          this.displayImagesLightBox = true;
          this.imageIndex = i;
        };
      }
    }
  },
  components: {
    ChangelogItem,
  },
  methods: {
    scrollToDiv(e: Event, divId: string) {
      e.preventDefault();

      const divElement = document.getElementById(divId);
      if (divElement) {
        divElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    showImagesLightBox() {
      this.displayImagesLightBox = true;
    },
    hideImagesLightBox() {
      this.displayImagesLightBox = false;
    },
  },
});
</script>

<style scoped>
.changelog-wrapper {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
}

.changelog-filters {
  background-color: #fcfcfc;
  padding: 20px 0 17px 20px;
  text-align: left;
  font-weight: 300;
  border: 1px solid #cbd3dd;
  position: relative;
  top: 1px;
  border-radius: 3px 3px 0 0;
}

.changelog-filters input {
  margin-right: 5px;
}

.changelog-scroll-to {
  position: absolute;
  display: block;
  top: 0;
  line-height: 73px;
  height: 100%;
  padding: 0 10px 0 25px;
  right: 0;
  cursor: pointer;
  border-left: 1px solid #dae0e7;
  -webkit-transition: background-color 0.25s ease;
  -moz-transition: background-color 0.25s ease;
  -ms-transition: background-color 0.25s ease;
  -o-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
  color: #676b6e;
}

.changelog-scroll-to-list {
  position: absolute;
  top: 100%;
  background-color: #fff;
  z-index: 5;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 225px;
  left: -1px;
  right: -1px;
  border: 1px solid #cbd3dd;
  text-align: center;
  overflow-y: scroll;
}

.changelog-scroll-to-list-item {
  border-bottom: 1px solid #eaedf1;
}

.changelog-checkbox,
.changelog-checkbox-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.changelog-checkbox + .changelog-checkbox-label:before {
  content: "";
  background: #fff;
  border: 1px solid #cbd3dd;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  padding: 2px;
  margin-right: 13px;
  text-align: center;
  margin-top: -4px;
}

.changelog-checkbox:checked + .changelog-checkbox-label:before {
  content: "\2713";
  background: #98c74e;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid transparent;
}

.changelog-checkbox {
  opacity: 0;
  position: absolute;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
