<template>
  <div class="row text-center mt-3 justify-content-center">
    <Loader v-if="loadingData" />
    <div class="col-sm-12 col-lg-6" v-else>
      <h1>Certyfikaty</h1>
      <div style="overflow-x: auto">
        <table class="table text-left">
          <thead>
            <tr>
              <th>Urządzenie</th>
              <th>Plik</th>
              <th>Wielkość</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="certificate in certificatesData" :key="certificate">
              <td>{{ certificate.FileDescription }}</td>
              <td>
                <a
                  :href="certificate.FilePath"
                  @click="downloadFile($event, certificate.FilePath)"
                >
                  <font-awesome-icon :icon="['fas', 'file']" />&nbsp;{{
                    certificate.FileName
                  }}
                </a>
              </td>
              <td>{{ certificate.FileSize }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "../services/apiService";
import Loader from "../components/Loader.vue";
import { CertificateModel } from "../models/certificateModel";

export default defineComponent({
  name: "Home",
  data() {
    return {
      loadingData: false,
      certificatesData: [] as CertificateModel[],
    };
  },
  components: {
    Loader,
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loadingData = true;
        const responseData = await ApiService.instance.getCertificatesData();
        if (responseData !== undefined) {
          this.certificatesData = responseData;
        }
      } catch (err) {
        console.log("ERROR: " + err);
      } finally {
        this.loadingData = false;
      }
    },
    downloadFile(e: Event, filePath: string) {
      e.preventDefault();
      window.location.href = filePath;
    },
  },
});
</script>

<style scoped></style>
