import { InstructionType } from "./instructionType";

export function GetPdfInstructionPath(instructionType: InstructionType) {
  switch (instructionType) {
    case InstructionType.RCPAdmin:
      return "/download/instructions/software/rcpadmin.pdf";

    case InstructionType.KDAdmin:
      return "/download/instructions/software/kdadmin.pdf";

    case InstructionType.KDPortier:
      return "/download/instructions/software/kdportier.pdf";

    case InstructionType.WebRCP:
      return "/download/instructions/software/webrcp.pdf";

    case InstructionType.RCPMini:
      return "/download/instructions/software/rcp_mini.pdf";

    case InstructionType.EmployeeProfile:
      return "/download/instructions/software/employee_profile.pdf";

    case InstructionType.SuperiorProfile:
      return "/download/instructions/software/superior_profile.pdf";

    case InstructionType.InstallationRecoveryTransfer:
      return "/download/instructions/software/installation_recovery_transfer.pdf";

    case InstructionType.AndroidOpendoor:
      return "/download/instructions/software/android_opendoor.pdf";

    case InstructionType.Card2Key:
      return "/download/instructions/software/card2key.pdf";

    case InstructionType.ZleceniaProjektyOperacje:
      return "/download/instructions/software/zlecenia_projekty_operacje.pdf";

    case InstructionType.Skalfi_mobile:
      return "/download/instructions/software/skalfi_mobile.pdf";

    case InstructionType.Skalfi_mobile_superior_time:
      return "/download/instructions/software/skalfi_mobile_superior_time.pdf";

    case InstructionType.SkalfiSetup:
      return "/download/instructions/software/skalfi_setup.pdf";
    case InstructionType.NotificationService:
      return "/download/instructions/software/notification_service.pdf";

    case InstructionType.WebAPIBasicInfo:
      return "/download/instructions/webapi/webapi_basic_info.pdf";

    case InstructionType.IntegrationWithST5Readers:
      return "/download/instructions/webapi/integration_with_st5_readers.pdf";

    case InstructionType.SP35:
      return "/download/instructions/devices/sp35.pdf";

    case InstructionType.SKD30:
      return "/download/instructions/devices/skd30.pdf";

    case InstructionType.SKD50:
      return "/download/instructions/devices/skd50.pdf";

    case InstructionType.SC210Mv2Connecting:
      return "/download/instructions/devices/sc210-mv2_connecting.pdf";

    case InstructionType.SCU100Installation:
      return "/download/instructions/devices/scu100_installation.pdf";

    case InstructionType.SCU115Connecting:
      return "/download/instructions/devices/scu115_connecting.pdf";

    case InstructionType.SCU120_R_Connecting:
      return "/download/instructions/devices/scu120-r_connecting.pdf";

    case InstructionType.SCU140Installation:
      return "/download/instructions/devices/scu140-installation.pdf";

    case InstructionType.SCU200Installation:
      return "/download/instructions/devices/scu200-installation.pdf";

    case InstructionType.SCU210_MV3:
      return "/download/instructions/devices/scu210-mv3_connecting.pdf";

    case InstructionType.SCU240Installation:
      return "/download/instructions/devices/scu240_installation.pdf";

    case InstructionType.SK10Connecting:
      return "/download/instructions/devices/sk10_connecting.pdf";

    case InstructionType.SK20Installation:
      return "/download/instructions/devices/sk20_installation.pdf";

    case InstructionType.SK26Installation:
      return "/download/instructions/devices/sk26_installation.pdf";

    case InstructionType.SR110Ev2_SBR110E:
      return "/download/instructions/devices/sr110-ev2_SBR110e_connecting_button.pdf";

    case InstructionType.INFOMAT:
      return "/download/instructions/devices/infomat.pdf";

    case InstructionType.SRM100:
      return "/download/instructions/devices/srm100.pdf";

    case InstructionType.SigmaLiteReaderConfiguration:
      return "/download/instructions/devices/sigma_lite_reader_configuration.pdf";

    case InstructionType.GUARD_MODULE:
      return "/download/instructions/software/guard_module.pdf";

    case InstructionType.SK50:
      return "/download/instructions/devices/sk50.pdf";

    case InstructionType.SK50_OSDP:
      return "/download/instructions/devices/sk50_osdp.pdf";

    case InstructionType.SK50_OSDP_SCU200:
      return "/download/instructions/devices/sk50_osdp_scu200.pdf";

    case InstructionType.SK50_OSDP_HID:
      return "/download/instructions/devices/sk50_osdp_hid.pdf";

    case InstructionType.SR200:
      return "/download/instructions/devices/sr200.pdf";

    case InstructionType.BuzzerLampToSR100SR110:
      return "/download/instructions/devices/buzzer_lamp_to_sr100_sr110.pdf";

    case InstructionType.Skalmex_support:
      return "/download/instructions/devices/skalmex_support.pdf";

    case InstructionType.SkalfiWeb:
      return "/download/instructions/software/skalfi_web.pdf";

    case InstructionType.WebRCPConfiguration:
      return "/download/instructions/software/web_rcp_configuration.pdf";

    case InstructionType.Skalmex_support_card:
      return "/download/instructions/software/skalmex_support_card.pdf";
  }
}
