<template>
  <div
    class="changelog-item-change"
    :class="{
      'changelog-feature': type == 0,
      'changelog-improvment': type == 1,
      'changelog-bug-fix': type == 2,
    }"
    style="display: block;"
  >
    <span class="changelog-type" v-if="type == 0">Dodano</span>
    <span class="changelog-type" v-else-if="type == 1">Ulepszenie</span>
    <span class="changelog-type" v-else-if="type == 2">Poprawka</span>

    <div class="card-body faq-item" v-html="content"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ChangelogChangeItem",
  props: {
    type: Number,
    content: String,
  },
});
</script>

<style scoped>
.changelog-item-change {
  line-height: 25px;
  position: relative;
  padding-left: 115px;
  margin-top: 20px;
  border-top: 1px solid #cbd3dd;
  padding-top: 20px;
  overflow-wrap: break-word;
}

.changelog-item-change.changelog-improvment .changelog-type {
  background-color: #4aa3ba;
}

.changelog-item-change.changelog-bug-fix .changelog-type {
  background-color: #da5c53;
}

.changelog-type {
  background-color: #98c74e;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  padding: 0 10px;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 100px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 19px;
}
</style>
