<template>
  <div class="container">
    <h1 class="text-center mt-4">{{ faqHeaderTitle }}</h1>
    <div class="accordion" id="faq_accordion">
      <Loader v-if="loadingData" />
      <FaqItem
        v-for="(item, index) in faqItems"
        :key="item"
        :itemIndex="index"
        :title="item.Title"
        :content="item.Content"
        dataParentName="#faq_accordion"
        v-else
      />

      <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="displayImagesLightBox"
        :imgs="images"
        :index="imageIndex"
        @hide="hideImagesLightBox"
      ></vue-easy-lightbox>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "../services/apiService";
import { FaqType } from "../common/faqType";
import FaqItem from "../components/FaqItem.vue";
import Loader from "../components/Loader.vue";
import { FaqItemDataModel } from "../models/faqItemDataModel";

export default defineComponent({
  name: "Faq",
  data() {
    return {
      faqItems: [] as FaqItemDataModel[],
      loadingData: false,
      displayImagesLightBox: false,
      images: [] as string[],
      imageIndex: 0,
    };
  },
  components: {
    FaqItem,
    Loader,
  },
  async created() {
    await this.fetchData(this.$route.params.faqType as FaqType);

    const rootFaqElement = document.getElementById("faq_accordion");
    if (rootFaqElement != null) {
      const imagesElements = rootFaqElement.getElementsByTagName("img");

      for (let i = 0; i < imagesElements.length; i++) {
        this.images.push(imagesElements[i].src);
        imagesElements[i].onclick = () => {
          this.displayImagesLightBox = true;
          this.imageIndex = i;
        };
      }
    }
  },
  methods: {
    async fetchData(faqType: FaqType) {
      try {
        this.loadingData = true;
        const responseData = await ApiService.instance.getFaqData(faqType);
        if (responseData !== undefined) {
          this.faqItems = responseData.data;
        }
      } catch (err) {
        console.log("ERROR: " + err);
      } finally {
        this.loadingData = false;
      }
    },
    showImagesLightBox() {
      this.displayImagesLightBox = true;
    },
    hideImagesLightBox() {
      this.displayImagesLightBox = false;
    },
  },
  computed: {
    faqHeaderTitle(): string {
      const faqType: FaqType | undefined = this.$route.params
        .faqType as FaqType;

      switch (faqType) {
        case FaqType.RCPAdmin:
          return "FAQ - RCPAdmin";
        case FaqType.KDAdmin:
          return "FAQ - KDAdmin";
        case FaqType.AccessControl:
          return "FAQ - Moduł Web";
        case FaqType.WebModule:
          return "FAQ - Moduł Web";
        case FaqType.RODO:
          return "FAQ - RODO";
        case FaqType.MiniStandardPremiumElite:
          return "FAQ - Mini Standard Premium Elite";
        case FaqType.Usage:
          return "FAQ - Użytkowanie";
      }
      /*if (faqType !== undefined)
        return FaqType[]*/

      return "Faq";
    },
  },
  watch: {
    async "$route.params.faqType"(faqType) {
      await this.fetchData(faqType as FaqType);
    },
  },
});
</script>

<style>
h2 {
  font-size: 8px;
}

div.faq-item > img {
  display: block;
  max-width: 100%;
}

div.faq-item > img {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

div.faq-item > img:hover {
  opacity: 0.7;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
</style>
