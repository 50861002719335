<template>
  <div class="row justify-content-center mt-5">
    <div class="col-lg-10">
      <div class="row">
        <div
          id="current_firmware_versions_container"
          class="col-sm-12 col-lg-4"
        >
          <h3>Najnowsze wersje</h3>
          <div class="card">
            <div class="card-body">
              <template v-if="!loadingProgramsData">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Program</th>
                      <th>Wersja</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in programsData" :key="item">
                      <td v-html="displayProgramName(item)"></td>
                      <td>{{ item.Version }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template v-else>
                <Loader />
              </template>
            </div>
          </div>
        </div>
        <div id="hardware_container" class="col-sm-12 col-lg-4">
          <h3>Sprzęt</h3>
          <div class="row">
            <div
              class="col-sm-12"
              v-for="device in devicesFirmwareData"
              :key="device"
            >
              <div class="card mb-1">
                <div class="card-body">
                  <h5 class="card-title">{{ device.Name }}</h5>
                  <h6 class="card-title">Najnowszy firmware</h6>

                  <template v-if="!loadingDevicesFirmwareData">
                    <div
                      class="row"
                      style="margin-left: 1px;"
                      v-for="firmware in device.Firmware"
                      :key="firmware"
                    >
                      <div class="col">
                        <div class="row">{{ firmware.Version }}</div>
                        <div v-if="firmware.FilePath">
                          <button
                            type="button"
                            class="btn btn-outline-success pull-right"
                            @click="downloadFile(firmware.FilePath)"
                          >
                            Pobierz
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <Loader />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="installers_container" class="col-sm-12 col-lg-4">
          <h3>Instalatory</h3>
          <div class="row justify-content-around">
            <div
              class="col-sm-12"
              v-for="installer in installersData"
              :key="installer"
            >
              <div class="card mb-1">
                <div class="card-body">
                  <h5 class="card-title">{{ installer.Name }}</h5>

                  <div>
                    <div class="row">
                      <div class="col">{{ installer.Description }}</div>
                    </div>

                    <div class="row" v-if="installer.FilePath">
                      <div class="col">
                        <button
                          type="button"
                          class="btn btn-outline-success pull-right"
                          @click="downloadFile(installer.FilePath)"
                        >
                          Pobierz
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "../services/apiService";
import Loader from "../components/Loader.vue";
import { HomeInfoModel } from "../models/homeInfoModel";
import { ProgramVersionModel } from "../models/programVersionModel";
import { DeviceModel } from "../models/deviceModel";
import { FirmwareModel } from "../models/firmwareModel";
import { InstallerModel } from "../models/installerModel";

export default defineComponent({
  name: "Home",
  data() {
    return {
      loadingProgramsData: false,
      programsData: [] as ProgramVersionModel[],

      loadingDevicesFirmwareData: false,
      devicesFirmwareData: [] as DeviceModel[],

      loadingInstallers: false,
      installersData: [] as InstallerModel[],
    };
  },
  components: {
    Loader,
  },
  async created() {
    this.fetchLastsProgramsVersions();
    this.fetchDevicesFirmwareData();
    this.fetchInstallers();
  },
  methods: {
    async fetchLastsProgramsVersions() {
      try {
        this.loadingProgramsData = true;
        const responseData = await ApiService.instance.getLastProgramsVersions();
        const responseDataWebAPI = await ApiService.instance.getLastWebAPIVersions();
        const responseDataWebRCP = await ApiService.instance.getLastWebRCPVersions();

        if (responseData) {
          this.programsData = responseData;
        }

        if (responseDataWebAPI) {
          this.programsData.push({
            Name: "WebAPI",
            Version: responseDataWebAPI,
          });
        }

        if (responseDataWebRCP) {
          this.programsData.push({
            Name: "WebRCP(SkalfiNext)",
            Version: responseDataWebRCP,
          });
        }
      } catch (err) {
        console.log("ERROR:");
      } finally {
        this.loadingProgramsData = false;
      }
    },
    async fetchDevicesFirmwareData() {
      try {
        this.loadingDevicesFirmwareData = true;

        await ApiService.instance
          .getSK50FirmwareData()
          .then(firmwareVersion => {
            if (firmwareVersion != undefined) {
              this.devicesFirmwareData.push(
                new DeviceModel("Kontroler SK40", [
                  new FirmwareModel(
                    firmwareVersion[0],
                    "http://hsu.skalmex.pl/firmware/download/Firmware_SK40.bin"
                  ),
                ])
              );

              this.devicesFirmwareData.push(
                new DeviceModel("Kontroler SK50", [
                  new FirmwareModel(
                    firmwareVersion[1],
                    "http://hsu.skalmex.pl/firmware/download/Firmware_SK50.bin"
                  ),
                ])
              );
            }
          });

        await ApiService.instance.getSK30FirmwareData().then(res => {
          if (res != undefined) {
            this.devicesFirmwareData.push(
              new DeviceModel("Kontroler SK30", [
                new FirmwareModel(res[0], ""),
                new FirmwareModel(res[1], ""),
              ])
            );
          }
        });

        await ApiService.instance.getSR200FirmwareData().then(res => {
          if (res != undefined) {
            this.devicesFirmwareData.push(
              new DeviceModel("Rejestrator SR200", [new FirmwareModel(res, "")])
            );
          }
        });
      } catch (err) {
        console.log("ERROR:");
      } finally {
        this.loadingDevicesFirmwareData = false;
      }
    },
    async fetchInstallers() {
      try {
        this.loadingInstallers = true;
        const responseData = await ApiService.instance.getInstallers();
        if (responseData) {
          this.installersData = responseData;
        }
      } catch (err) {
        console.log("ERROR:");
      } finally {
        this.loadingInstallers = false;
      }
    },
    downloadFile(filePath) {
      window.location = filePath;
    },
    displayProgramName(obj) {
      let demoLink = "";
      if (obj.DemoLink) {
        demoLink = `(<a href='${obj.DemoLink}'>Pobierz demo</a>)`;
      }

      return `${obj.Name} ${demoLink}`;
    },
  },
});
</script>

<style scoped></style>
