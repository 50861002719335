<template>
  <div :id="'v_' + version" class="changelog-item">
    <header>
      <h3 class="changelog-version">
        <a href="#" @click="scrollToDiv($event, 'v_' + version)"
          >v{{ version }}</a
        >
      </h3>
      <p class="changelog-date">{{ date }}</p>
    </header>

    <div class="changelog-item-changes">
      <ChangelogChangeItem
        v-for="item in filteredChanges"
        :key="item"
        :type="item.Type"
        :content="item.Content"
      />
    </div>

    <div class="changelog-link"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ChangelogChangeItem from "./ChangelogChangeItem.vue";
import { ChangelogChangeItemDataModel } from "./../models/changelogChangeItemDataModel";
import { ChangelogChangeType } from "./../common/changelogChangeType";

export default defineComponent({
  name: "ChangelogItem",
  props: {
    itemIndex: Number,
    version: String,
    date: String,
    changes: Array as () => Array<ChangelogChangeItemDataModel>,
    displayFeatures: Boolean,
    displayImprovments: Boolean,
    displayBugFixes: Boolean,
  },
  data() {
    return {};
  },
  components: {
    ChangelogChangeItem,
  },
  computed: {
    filteredChanges(): Array<ChangelogChangeItemDataModel> | undefined {
      return this.changes?.filter(v => {
        if (
          (this.displayFeatures && v.Type == ChangelogChangeType.Feature) ||
          (this.displayImprovments &&
            v.Type == ChangelogChangeType.Improvment) ||
          (this.displayBugFixes && v.Type == ChangelogChangeType.Fix)
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  methods: {
    scrollToDiv(e: Event, divId: string) {
      e.preventDefault();

      const divElement = document.getElementById(divId);
      if (divElement) {
        divElement.scrollIntoView({ behavior: "smooth" });
      }

      return true;
    },
  },
});
</script>

<style scoped>
blockquote,
p {
  margin: 0 0 1em;
}

.changelog-item {
  border: 1px solid #cbd3dd;
  padding: 30px 40px;
  margin-bottom: 100px;
  position: relative;
  border-radius: 3px;
}

.changelog-item:before {
  top: -4px;
}

.changelog-item:after {
  bottom: -4px;
}

.changelog-item:after,
.changelog-item:before {
  display: block;
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  border: 1px solid #cbd3dd;
  border-radius: 50%;
  left: 50%;
  background-color: #fff;
  margin-left: -4px;
  z-index: 2;
}

.changelog-item:first-child:before,
.changelog-item:last-child .changelog-link,
.changelog-item:last-child:after {
  display: none;
}

.changelog-header {
  position: relative;
}

.changelog-version {
  margin: 0;
}

.changelog-date {
  margin: 5px 0 0;
  font-size: 0.875em;
  color: #808488;
}

.changelog-item-changes {
}

.changelog-item-change {
  line-height: 25px;
  position: relative;
  padding-left: 115px;
  margin-top: 20px;
  border-top: 1px solid #cbd3dd;
  padding-top: 20px;
}

.changelog-item-change.changelog-improvment .changelog-type {
  background-color: #4aa3ba;
}

.changelog-item-change.changelog-bug-fix .changelog-type {
  background-color: #da5c53;
}

.changelog-type {
  background-color: #98c74e;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  padding: 0 10px;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 100px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 19px;
}

.changelog-link {
  position: absolute;
  bottom: -101px;
  left: 50%;
  width: 1px;
  height: 100px;
  background-color: #cbd3dd;
}
</style>
