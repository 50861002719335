<template>
  <div id="app">
    <header class="sticky" style="z-index: 1000">
      <nav class="navbar navbar-expand-md navbar-dark bg-dark">
        <div class="navbar-header">
          <button
            type="button"
            class="button navbar-toggle collapsed navbar-static-top"
            data-toggle="collapse"
            data-target="#navbar-collapse-1"
          >
            <font-awesome-icon :icon="['fas', 'bars']" />
          </button>

          <button
            id="tocButton"
            type="button"
            class="button"
            data-toggle="modal"
            data-target="#tocModal"
          >
            <font-awesome-icon :icon="['fas', 'list']" />
            Spis treści
          </button>
        </div>

        <div class="collapse navbar-collapse" id="navbar-collapse-1">
          <ul class="navbar-nav mr-auto row">
            <li class="nav-item">
              <router-link class="nav-link" to="/"
                ><font-awesome-icon
                  :icon="['fas', 'home']"
                  style="color: #dc1212"
              /></router-link>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownSoftInstructions"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <font-awesome-icon :icon="['fas', 'file']" />&nbsp; Instrukcje
                oprogramowania
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownSoftInstructions"
              >
                <router-link class="dropdown-item" to="/instructions/rcpadmin"
                  >RCPAdmin</router-link
                >

                <router-link class="dropdown-item" to="/instructions/kdadmin"
                  >KDAdmin</router-link
                >

                <router-link class="dropdown-item" to="/instructions/kdportier"
                  >KDPortier</router-link
                >

                <router-link class="dropdown-item" to="/instructions/rcp_mini"
                  >RCP Mini</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/employee_profile"
                  >WebRCP - Profil pracownika
                </router-link>

                <router-link
                  class="dropdown-item"
                  to="/instructions/superior_profile"
                  >WebRCP - Profil przełożonego</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/installation_recovery_transfer"
                  >Instalacja/Odzyskiwanie/Przenoszenie/Aktualizacja systemu
                  SKALFI.NET</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/android_opendoor"
                  >Android Opendoor</router-link
                >

                <router-link class="dropdown-item" to="/instructions/card2key"
                  >Program Card2Key</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/zlecenia_projekty_operacje"
                  >Zlecenia, projekty, operacje</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/guard_module"
                  >Moduł strażnika</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/skalfi_mobile"
                  >Skalfi-mobile (RCP Pracownik)</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/skalfi_mobile_superior_time"
                  >Skalfi-mobile (RCP Przełożony)</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/skalfi_setup"
                  >SkalfiSetup</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/skalmex_support"
                  >Skalmex Support</router-link
                >

                <router-link class="dropdown-item" to="/instructions/skalfi_web"
                  >SkalfiWeb</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/web_rcp_configuration"
                  >SkalfiWeb Konfiguracja</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/skalmex_support_card"
                  >Skalmex Support (Karta NFC)</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/instructions/notification_service"
                  >Notification Service
                </router-link>
              </div>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownDevicesInstructions"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <font-awesome-icon :icon="['fas', 'file']" />&nbsp; Instrukcje
                do sprzętu
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownDevicesInstructions"
              >
                <router-link class="dropdown-item" to="/instructions/sp35"
                  >SP35</router-link
                >

                <router-link class="dropdown-item" to="/instructions/skd30"
                  >SKD30</router-link
                >
                <router-link class="dropdown-item" to="/instructions/skd50"
                  >SKD50</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/sc210-mv2_connecting"
                  >SC210-Mv2 - Podłączanie</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/scu100_installation"
                  >SCU100 - Instalacja</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/scu115_connecting"
                  >SCU115 - Podłączanie</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/scu120-r_connecting"
                  >SCU120-R - Podłączanie</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/scu140-installation"
                  >SCU140 - Instalacja</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/scu200-installation"
                  >SCU200 - Instalacja</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/scu210-mv3_connecting"
                  >SCU210-Mv3 - Podłączanie</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/scu240_installation"
                  >SCU240 - Instalacja</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/sk10_connecting"
                  >SK10 - Podłączanie</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/sk20_installation"
                  >SK20 - Instalacja</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/sk26_installation"
                  >SK26 - Instalacja</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/sr110-ev2_SBR110e_connecting_button"
                  >SR110-Ev2 SBR110E - Podłączanie przycisku</router-link
                >
                <router-link class="dropdown-item" to="/instructions/infomat"
                  >INFOMAT</router-link
                >

                <router-link class="dropdown-item" to="/instructions/srm100"
                  >SRM100 Rejestrator mobilny</router-link
                >

                <router-link class="dropdown-item" to="/instructions/sr200"
                  >Rejestrator SR200</router-link
                >

                <router-link class="dropdown-item" to="/instructions/sk50"
                  >SK50</router-link
                >
                <router-link class="dropdown-item" to="/instructions/sk50_osdp"
                  >SK50 - Współpraca z czytnikami OSDP</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/sk50_osdp_scu200"
                  >SK50 - Współpraca z czytnikami SCU200</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/sk50_osdp_hid"
                  >SK50 - Współpraca z czytnikami HID</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/buzzer_lamp_to_sr100_sr110"
                  >Podłączenie buzzera/lampki sygnalizacyjnej do rejestratora
                  SR100/SR110</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/instructions/sigma_lite_reader_configuration"
                  >Konfiguracja czytnika Sigma Lite</router-link
                >
              </div>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownWebAPIInstructions"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <font-awesome-icon :icon="['fas', 'file']" />&nbsp;WebAPI
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownWebAPIInstructions"
              >
                <router-link
                  class="dropdown-item"
                  to="/webapi/webapi_basic_info"
                  >Informacje podstawowe</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/webapi/integration_with_st5_readers"
                  >Integracja z czytnikiem ST5</router-link
                >
              </div>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/technical_sheets"
                ><font-awesome-icon :icon="['fas', 'file']" />&nbsp; Karty
                techniczne</router-link
              >
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/certificates"
                ><font-awesome-icon :icon="['fas', 'certificate']" />&nbsp;
                Certyfikaty</router-link
              >
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownFaq"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <font-awesome-icon :icon="['fas', 'question']" />&nbsp; FAQ
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownDevicesInstructions"
              >
                <router-link class="dropdown-item" to="/faq/rcpadmin"
                  >RCPAdmin</router-link
                >

                <router-link class="dropdown-item" to="/faq/kdadmin"
                  >KDAdmin</router-link
                >

                <router-link class="dropdown-item" to="/faq/access_control"
                  >Kontrola dostępu</router-link
                >

                <router-link class="dropdown-item" to="/faq/web_module"
                  >Moduł WEB</router-link
                >

                <router-link class="dropdown-item" to="/faq/rodo"
                  >RODO</router-link
                >

                <router-link class="dropdown-item" to="/faq/export_import"
                  >Eksport / Import</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/faq/mini_standard_premium_elite"
                  >Mini Standard Premium Elite</router-link
                >

                <router-link class="dropdown-item" to="/faq/usage"
                  >Użytkowanie</router-link
                >
              </div>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownFaq"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <font-awesome-icon :icon="['fas', 'clipboard-list']" />&nbsp;
                Opis zmian
              </a>

              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownDevicesInstructions"
              >
                <router-link class="dropdown-item" to="/changelog/Infomat"
                  >Infomat</router-link
                >

                <router-link class="dropdown-item" to="/changelog/rcpadmin"
                  >RCPAdmin</router-link
                >

                <router-link class="dropdown-item" to="/changelog/kdadmin"
                  >KDAdmin</router-link
                >
                <router-link class="dropdown-item" to="/changelog/sk50"
                  >Kontroler SK50</router-link
                >

                <router-link class="dropdown-item" to="/changelog/sr200_sr300"
                  >SR200/SR300</router-link
                >

                <router-link class="dropdown-item" to="/changelog/web_module"
                  >Moduł WEB</router-link
                >

                <router-link
                  class="dropdown-item"
                  to="/changelog/new_web_module"
                  >Nowy Moduł WEB</router-link
                >

                <router-link class="dropdown-item" to="/changelog/webapi"
                  >WebAPI</router-link
                >

                <router-link class="dropdown-item" to="/changelog/SRM100"
                  >SRM100</router-link
                >

                <router-link class="dropdown-item" to="/changelog/Skalfi-mobile"
                  >Skalfi-mobile</router-link
                >
              </div>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/contact"
                ><font-awesome-icon
                  :icon="['fas', 'user']"
                />&nbsp;Kontakt</router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <main class="container-fluid">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="$route.fullPath"></component>
        </transition>
      </router-view>
    </main>

    <footer class="footer bg-dark text-center" style="text-align: center">
      <span class="text-muted" style="margin: 0 auto"
        ><img src="@/assets/skalmex_footer_logo.png"
      /></span>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import Vue from "vue";
import $ from "jquery";

export default defineComponent({
  Name: "App",
  data() {
    return {};
  },
  mounted() {
    $(".dropdown-item").on("click", function() {
      ($(".navbar-collapse") as any).collapse("hide");
    });

    $('a[class="nav-link"]').on("click", function() {
      ($(".navbar-collapse") as any).collapse("hide");
    });
  },
  watch: {
    $route(to, from) {
      const tocButtonElement = document.getElementById("tocButton")!;

      if (to.fullPath.startsWith("/instructions")) {
        tocButtonElement.style.visibility = "visible";
      } else {
        tocButtonElement.style.visibility = "hidden";
      }
    },
  },
});
</script>

<style>
@media (max-width: 767px) {
  .navbar-header {
    display: block;
  }

  .sticky {
    position: sticky;
    top: 0;
  }
}

@media (min-width: 768px) {
  .navbar-header {
    display: none;
  }
}

main {
  min-height: 88vh;
}

main {
  font-size: 14px;
  line-height: 1.42857143;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  /*text-align: Center;*/
  box-sizing: border-box;
  background-color: transparent;
  text-decoration: none;
  color: #767676;
}

b {
  font-weight: bold;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

ul {
  margin: 0;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "-";
  text-indent: -5px;
}

nav ul li {
  margin-left: 20px;
}

/* unvisited link */
a:link {
  color: #767676;
}

/* visited link */
a:visited {
  color: #767676;
}

/* mouse over link */
a:hover {
  color: black;
}

/* selected link */
a:active {
  color: black;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
